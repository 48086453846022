import * as React from 'react';
import * as classnames from 'classnames/bind';
import {HBoxVAlign} from './HBox';

const styles = require('./VBox.less');
const cx = classnames.bind(styles) as any;

export enum VBoxHAlign
{
    center = 'center',
    flexEnd = 'flex-end',
    flexStart = 'flex-start'
}

export interface VBoxProps
{
    className?:string;
    halign?:VBoxHAlign;
    style?:any;
    padding?:number;
}

export class VBox extends React.Component<VBoxProps, any>
{
    render()
    {
        const {className, halign=VBoxHAlign.center, style=null, padding=NaN} = this.props;

        let oStyle = {alignItems:halign};

        if( !isNaN(padding) )
            (oStyle as any).padding = padding;

        if( style )
            oStyle = Object.assign(oStyle, style);

        return  <div style={oStyle} className={cx('vbox', className)}>{this.props.children}</div>;
    }
}