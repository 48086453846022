import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./PictureFrame.less');
const cx = classnames.bind(styles) as any;

export interface PictureFrameProps
{
    className?:string;
    style?:any;
}

export class PictureFrame extends React.Component<PictureFrameProps, any>
{
    render()
    {
        const {style={}} = this.props;

        return <div style={style} className={cx('pictureFrame')}>{this.props.children}</div>;
    }
}