import * as React from 'react';
import * as classnames from 'classnames/bind';
import {Text, TextSize, TextWeight} from './Text';
import {VSpacer} from './VSpacer';
import {MenuItem} from './TopMenu';

const styles = require('./FooterBlock.less');
const cx = classnames.bind(styles) as any;

export interface FooterBlockProps
{
    title:string;
    footerItems:MenuItem[];
}

export class FooterBlock extends React.Component<FooterBlockProps, any>
{
    render()
    {
        const {title, footerItems} = this.props;
        const vSpacingText = 3;

        return <Text className={cx('footerBlock')} color={'white'} size={TextSize.h4} weight={TextWeight.w500} padding={'0px 0px'}>
                <span className={cx('title')}>{title}</span>

                <Text size={TextSize.h5} weight={TextWeight.w300} padding={'8px 0px'} style={{display:'flex', flexDirection:'column'}}>
                    {
                        footerItems.map((fi:MenuItem)=>
                        {
                            return  <a key={fi.title} className={'footerLink2'} href={fi.link}>{fi.title}
                                        <VSpacer size={vSpacingText}/>
                                    </a>;
                        })
                    }
                </Text>
            </Text>;
    }
}