import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./PageBackground.less');
const cx = classnames.bind(styles) as any;

export class PageBackground extends React.Component<any, any>
{
    render()
    {
        return  <div className={cx('pageBackground')}>
                    {this.props.children}
                </div>;
    }
}