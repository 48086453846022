import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./HBox.less');
const cx = classnames.bind(styles) as any;

export enum HBoxVAlign
{
    center = 'center',
    flexEnd = 'flex-end',
    flexStart = 'flex-start'
}

export interface HBoxProps
{
    className?:string;
    style?:any;
    valign?:HBoxVAlign;
    padding?:number;
}

export class HBox extends React.Component<HBoxProps, any>
{
    render()
    {
        const {className, valign=HBoxVAlign.center, padding=NaN, style=null} = this.props;

        let oStyle = {alignItems:valign};

        if( !isNaN(padding) )
            (oStyle as any).padding = padding;

        if( style )
            oStyle = Object.assign(oStyle, style);

        return  <div style={oStyle} className={cx('hbox', className)}>{this.props.children}</div>;
    }
}