import * as React from 'react';
import * as classnames from 'classnames/bind';
import {HBox} from './HBox';
import {Images} from '../images/Images';

const styles = require('./TopMenu.less');
const cx = classnames.bind(styles) as any;

export class MenuItem
{
    constructor(public title:string,
                public link:string)
    {
        this.title = title;
        this.link = link;
    }
}

export interface TopMenuProps
{
    menuItems:MenuItem[];
}

export class TopMenu extends React.Component<TopMenuProps, any>
{
    constructor(props:any)
    {
        super(props);

        this.state =
        {
            isHamburgerOpen: false
        };
    }

    private _renderRegular()
    {
        const {menuItems} = this.props;

        return  <HBox className={cx('topMenu')}>
                {
                    menuItems.map((mi:MenuItem)=>
                                  {
                                      return <a key={mi.title} href={mi.link}>{mi.title}</a>;
                                  })
                }
                </HBox>;
    }

    private _renderHamburger()
    {
        return  <img onClick={()=>{this.setState({isHamburgerOpen:!this.state.isHamburgerOpen})}}
                     className={cx('hamburgerMenu')} src={Images.HamburgerIcon}/>;
    }

    private _renderHamburgerMenuItems()
    {
        if( !this.state.isHamburgerOpen )
            return null;

        const {menuItems} = this.props;
        return <div className={cx('hamburgerMenuItems')}>
            {
                menuItems.map((mi:MenuItem)=>
                {
                    return <a className={cx('hamburgerMenuLink')} href={mi.link}>{mi.title}</a>;
                })
            }
        </div>;
    }

    render()
    {
        return  <span>
                    {this._renderRegular()}
                    {this._renderHamburger()}
                    {this._renderHamburgerMenuItems()}
                </span>
    }
}