import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./Text.less');
const cx = classnames.bind(styles) as any;

export enum TextSize
{
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
}

export enum TextWeight
{
    w200    = 200,
    w300    = 300,
    w400    = 400,
    w500    = 500,
    w600    = 600,
    w700    = 700
}

export interface TextHeaderProps
{
    color?:string;
    size?:TextSize;
    spacing?:number;
    padding?:number|string;
    className?:string;
    style?:any;
    weight?:TextWeight;
    isHighlight?:boolean;
    lineHeight?:number;
}

export class Text extends React.Component<TextHeaderProps, any>
{
    render()
    {
        const {size=TextSize.h1, padding=0, className=null, style=null, weight=TextWeight.w300, isHighlight=false, spacing=0, color='', lineHeight=NaN} = this.props;
        const sizeName = TextSize[size];

        let oStyle = {padding:padding, fontWeight:weight, letterSpacing:spacing, color:color};

        if( !isNaN(lineHeight) )
            (oStyle as any).lineHeight = lineHeight;

        if( style )
            oStyle = Object.assign(oStyle, style);

        return  <span style={oStyle} className={cx( 'text',
                                                    sizeName,
                                                    className,
                                                    {'highlightedText':isHighlight}
                                                  )}>
                    {this.props.children}
                </span>;
    }
}