import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./HAligner.less');
const cx = classnames.bind(styles);

export enum HAlign
{
    Left = 'left',
    Right = 'right',
    Center = 'center'
}

export interface HAlignerProps
{
    align:HAlign;
}

export class HAligner extends React.Component<HAlignerProps, any>
{
    render()
    {
        const {align} = this.props;
        return  <div style={{textAlign:align}}>{this.props.children}</div>;
    }
}