import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./VSpacer.less');
const cx = classnames.bind(styles);

export interface VSpacerProps
{
    size:number;
}

export class VSpacer extends React.Component<VSpacerProps, any>
{
    render()
    {
        const {size} = this.props;
        return  <div style={{height:size}}/>;
    }
}