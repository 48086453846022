import * as React from 'react';
import * as classnames from 'classnames/bind';

const styles = require('./SiteButton.less');
const cx = classnames.bind(styles) as any;

export enum SiteButtonKind
{
    Red,
    Dark
}

export interface SiteButtonProps
{
    text:string;
    link:string;
    minWidth?:number;
    style?:any;
    kind?:SiteButtonKind;
    onClick?:any;
}

export class SiteButton extends React.Component<SiteButtonProps, any>
{
    render()
    {
        const {text, link, minWidth=105, style=null, kind=SiteButtonKind.Red} = this.props;

        let oStyle = {minWidth:minWidth};
        if( style )
            oStyle = Object.assign(oStyle, style);

        return  <a style={oStyle} className={cx('siteButton',
                                                {'redSiteButton':kind==SiteButtonKind.Red},
                                                {'darkSiteButton':kind==SiteButtonKind.Dark}
                                                )}
                   href={link}
                   onClick={this.props.onClick}>
                        {text}
                </a>;
    }
}